import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const authModule = {
	state: {
		authenticated: false,
		user: null,
	},
	mutations: {
		SET_AUTHENTICATED(state, value) {
			state.authenticated = value;
		},

		SET_USER(state, value) {
			state.user = value;
		},
	},
	actions: {
		async signIn(context, credentials) {
			await axios.get("/sanctum/csrf-cookie");

			return new Promise((resolve, reject) => {
				axios.post("/login", credentials).then(() => {
					resolve();
				}).catch((error) => {
					reject(error.response.data);
				});
			});
		},

		async signOut({ commit }) {
			await axios.post("/logout");

			commit("SET_AUTHENTICATED", false);
			commit("SET_USER", null);
		},

		async forgotPassword(context, data) {
			await axios.get("/sanctum/csrf-cookie");

			return new Promise((resolve, reject) => {
				axios.post("/forgot-password", data).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response.data);
				});
			});
		},

		async resetPassword(context, data) {
			await axios.get("/sanctum/csrf-cookie");

			return new Promise((resolve, reject) => {
				axios.post("/reset-password", data).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response.data);
				});
			});
		},

		async refreshCsrf() {
			return axios.get("/sanctum/csrf-cookie");
		},

		me({ commit }) {
			return new Promise((resolve, reject) => {
				axios.get("/api/user")
					.then((response) => {
						commit("SET_AUTHENTICATED", true);
						commit("SET_USER", response.data);

						resolve();
					})
					.catch(() => {
						commit("SET_AUTHENTICATED", false);
						commit("SET_USER", null);

						reject();
					});
			});
		},
	},
	getters: {
		authenticated(state) {
			return state.authenticated;
		},

		user(state) {
			return state.user;
		},

		isAdmin(state) {
			return state.user.agency === "admin";
		}
	},
};

export default authModule;
