<template>
	<v-dialog v-model="dialog" width="500" persistent>
		<v-card v-if="error">
			<v-card-title>
				Fout {{ error.status }}: {{ error.statusText }}
			</v-card-title>

			<v-card-text class="pb-0">
				<v-alert class="mb-0" type="error" text dense>
					Oeps! Er is iets misgegaan. Als dit blijft gebeuren, neem dan contact op met support.
				</v-alert>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn type="submit" color="primary" text @click="close">
					Ok
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			error: null,
		};
	},
	methods: {
		open(error) {
			this.error = error;
			this.dialog = true;
			console.log(error);
		},
		close() {
			this.dialog = false;
		},
	},
};
</script>

<style scoped>
</style>